<template>
  <form
    :ref="refs.paymentForm"
    method="post"
    target="_blank"
    :action="currentActiveBilling"
  >
    <div>
      <input
        v-for="(value, name) in readyOrder"
        :key="name"
        :name="name"
        :value="value"
        type="hidden"
      />
    </div>
    <slot></slot>
  </form>
</template>
<script setup>
import { computed } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import { refs } from '@/components/companyControlsCenter/helpers/index.js'

const { useGetters } = createVuexHelpers()

const { companyBilling } = useGetters('login', ['companyBilling'])
const { order } = useGetters('billing', ['order'])

const currentActiveBilling = computed(() => {
  return companyBilling.value.system.payment_url;
})

const readyOrder = computed(() => {
  if (!order.value) return

  const arr = {}

  Object.entries(order.value).forEach((item) => {
    arr[item[0]] =
      typeof item[1] === 'object' ? JSON.stringify(item[1]) : item[1]
  })

  return arr
})

</script>

<style scoped lang="scss">
@import '~@/assets/styles/mixins';

.pay-frame {
  margin: 0 auto;
  border: none;
  border-radius: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  @include scrollbar();
}

#balance-div-frame {
  display: block;
  position: relative;
  overflow: hidden;
  width: 1200px;
  height: 690px;
}

@media screen and (max-width: 1300px) {
  #balance-div-frame {
    width: 1000px;
  }
}

@media screen and (max-width: 1100px) {
  #balance-div-frame {
    width: 780px;
    max-width: 100%;
    height: 680px;
  }
}
</style>

