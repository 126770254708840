<template>
  <div
    v-loading="loading"
    class="bg-white fixed h-max-content z-10 rounded-xl max-w-25 balance-container modal-shadow"
    :class="[
      {
        'modal-balance': isNoHeader,
        'no-margin-top': !modalBalance,
        'w-full': !isNoHeader
      }
    ]"
  >
    <ModalWrapper
      v-show="showModalWrapper"
      :no-header="isNoHeader"
      :submit-text="$t('settings.balance.top_up')"
      :is-disabled="isSubmitDisabled"
      :title="$t('settings.balance.top_up_title')"
      :header-classes="['divider']"
      :footer-classes="[
        'divider-before',
        { 'global-hidden-buttons': isNoHeader }
      ]"
      :content-styles="[{ 'global-padding-zero': isNoHeader }]"
      is-cancel
      @onClose="$emit('onClose')"
      @onSubmit="topUpBalance"
    >
      <template v-if="showBalanceForm && !props.amount" #subtitle>
        <p class="text-grey-200">
          {{ $t('settings.balance.company') }} {{ activeCompany.name }}
        </p>
      </template>
      <div>
        <template v-if="showBalanceForm">
        <el-form
          :model="data"
          :rules="rules"
          :ref="refs.topUpBalanceValidation"
          class="flex flex-col"
        >
          <div class="relative">
            <span class="object-basic__info__label input-label">
              {{ $t('settings.balance.top_up_amount') }}
              ({{ currency }})
            </span>
            <el-form-item  prop="amount">
              <el-input
                v-model="data.amount"
                type="number"
                autocomplete="off"
                @keydown.native="onKeyDown"
              />
            </el-form-item>
          </div>
          <span class="text-xs font-normal text-grey-100 ml-3 mt-2">
            {{ $t('settings.balance.min_amount') }} {{ minPayAmount }} {{ currency }}
          </span>
        </el-form>
        <div v-if="showAutoPay" class="checkbox flex flex-col gap-2 mt-4 ml-3">
          <el-checkbox
            v-model="isAutoPay"
            :label="$t('settings.balance.turn_on_autopay')"
          />
        </div>
      </template>
      </div>

      <PaymentForm @onClose="$emit('onClose')" :sendedForm="sendedForm" v-if="!isSubmitDisabled" />
    </ModalWrapper>
  </div>
</template>
<script setup>
import {
  ref,
  getCurrentInstance,
  computed,
  defineProps,
  onBeforeMount
} from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { refs } from '@/components/companyControlsCenter/helpers/index.js'
import ModalWrapper from '@/components/companyControlsCenter/components/ControlsModalWrapper.vue'
import PaymentForm from '@/components/companyControlsCenter/components/PaymentForm.vue'

const { useGetters, useActions } = createVuexHelpers()

const props = defineProps({
  createOrder: {
    type: Boolean,
    default: true
  },
  amount: {
    type: Number,
    default: null
  },
  modalBalance: {
    type: Boolean,
    default: true
  }
});

const { companyBilling, activeCompany, currency, user } = useGetters('login', [
  'companyBilling',
  'activeCompany',
  'currency',
  'user'
])

const { CREATE_ORDER } = useActions('billing', ['CREATE_ORDER'])

const $t = useI18n()
const instance = getCurrentInstance()
const MODULBANK = 'MODULBANK';
const data = ref({})
const isNoHeader = ref(false)
const sendedForm = ref(false)
const emits = defineEmits(['onClose'])
const paymentPayload = {}
const loading = ref(false)
const isAutoPay = ref(false)
const showBalanceForm = ref(true);
const showModalWrapper = ref(true);

const checkNumber = (rule, value, callback) => {
  if (!value) {
    callback(
      new Error(
        `${$t('settings.balance.min_amount')} ${minPayAmount.value} ${
          currency.value
        }`
      )
    )
  } else {
    if (value >= minPayAmount.value) {
      callback()
    } else {
      callback(
        new Error(
          `${$t('settings.balance.min_amount')} ${minPayAmount.value}  ${
            currency.value
          }`
        )
      )
    }
  }
}

const rules = ref({
  amount: [
    {
      validator: checkNumber,
      required: true,
      trigger: 'blur'
    }
  ]
})

const notValidChars = ['.', ',', 'e', '-', '+', 'v', 'Enter']

const isSubmitDisabled = computed(() => {
  return false;
})

const minPayAmount = computed(() => {
  return companyBilling.value.system.min_pay_amount;
})

const showAutoPay = computed(() => {
  return Boolean(user.value.active_company.billing?.public_offer_path_auto_pay) && companyBilling.value.system.type === MODULBANK;
})

const onKeyDown = (event) => {
  if (notValidChars.includes(event.key)) event.preventDefault()

  if (event.key === 'Enter') {
    topUpBalance()
  };
}

const topUpBalance = async () => {
  await refs.topUpBalanceValidation.value.validate(async (value) => {
    if (!value) return

    topUpHandler()
  })
}

const topUpHandler = async (createOrder = true) => {
  try {
    loading.value = true

    if (isSubmitDisabled.value) return

    paymentPayload.amount = Math.abs(data.value.amount)
    paymentPayload.start_recurrent = 0
    if (isAutoPay.value) paymentPayload.start_recurrent = 1

    if (createOrder) {
      await CREATE_ORDER(paymentPayload)
    }

    /**
     * Make Width Modal
     * */
    refs.paymentForm.value.submit()
    showBalanceForm.value = false;
    isNoHeader.value = true;
    emits('onClose');
  } catch (err) {
    instance.proxy.$showError(err.message)
  } finally {
    loading.value = false
  }
}

onBeforeMount(async () => {
  if (props.amount) {
    showModalWrapper.value = false
    data.value.amount = props.amount
    await topUpHandler();
    showModalWrapper.value = true;
  }
})
</script>
<style>
.global-padding-zero {
  padding: 0;
}

.global-hidden-buttons {
  display: none;
}
</style>
<style lang="scss" scoped>
@import '../../assets/styles/BalanceTopUp.scss';

.modal-balance {
  box-shadow: 0 4px 16px 0 #00000029;
  max-width: 100%;
  margin: 0 auto;
  background: white;
  min-width: fit-content;
}

.modal-balance.no-margin-top {
  top: 0;
}
</style>
