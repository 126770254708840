var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_setup.loading),expression:"loading"}],staticClass:"bg-white fixed h-max-content z-10 rounded-xl max-w-25 balance-container modal-shadow",class:[
    {
      'modal-balance': _setup.isNoHeader,
      'no-margin-top': !_vm.modalBalance,
      'w-full': !_setup.isNoHeader
    }
  ]},[_c(_setup.ModalWrapper,{directives:[{name:"show",rawName:"v-show",value:(_setup.showModalWrapper),expression:"showModalWrapper"}],attrs:{"no-header":_setup.isNoHeader,"submit-text":_setup.$t('settings.balance.top_up'),"is-disabled":_setup.isSubmitDisabled,"title":_setup.$t('settings.balance.top_up_title'),"header-classes":['divider'],"footer-classes":[
      'divider-before',
      { 'global-hidden-buttons': _setup.isNoHeader }
    ],"content-styles":[{ 'global-padding-zero': _setup.isNoHeader }],"is-cancel":""},on:{"onClose":function($event){return _vm.$emit('onClose')},"onSubmit":_setup.topUpBalance},scopedSlots:_vm._u([(_setup.showBalanceForm && !_setup.props.amount)?{key:"subtitle",fn:function(){return [_c('p',{staticClass:"text-grey-200"},[_vm._v(" "+_vm._s(_setup.$t('settings.balance.company'))+" "+_vm._s(_setup.activeCompany.name)+" ")])]},proxy:true}:null],null,true)},[_c('div',[(_setup.showBalanceForm)?[_c('el-form',{ref:_setup.refs.topUpBalanceValidation,staticClass:"flex flex-col",attrs:{"model":_setup.data,"rules":_setup.rules}},[_c('div',{staticClass:"relative"},[_c('span',{staticClass:"object-basic__info__label input-label"},[_vm._v(" "+_vm._s(_setup.$t('settings.balance.top_up_amount'))+" ("+_vm._s(_setup.currency)+") ")]),_c('el-form-item',{attrs:{"prop":"amount"}},[_c('el-input',{attrs:{"type":"number","autocomplete":"off"},nativeOn:{"keydown":function($event){return _setup.onKeyDown.apply(null, arguments)}},model:{value:(_setup.data.amount),callback:function ($$v) {_vm.$set(_setup.data, "amount", $$v)},expression:"data.amount"}})],1)],1),_c('span',{staticClass:"text-xs font-normal text-grey-100 ml-3 mt-2"},[_vm._v(" "+_vm._s(_setup.$t('settings.balance.min_amount'))+" "+_vm._s(_setup.minPayAmount)+" "+_vm._s(_setup.currency)+" ")])]),(_setup.showAutoPay)?_c('div',{staticClass:"checkbox flex flex-col gap-2 mt-4 ml-3"},[_c('el-checkbox',{attrs:{"label":_setup.$t('settings.balance.turn_on_autopay')},model:{value:(_setup.isAutoPay),callback:function ($$v) {_setup.isAutoPay=$$v},expression:"isAutoPay"}})],1):_vm._e()]:_vm._e()],2),(!_setup.isSubmitDisabled)?_c(_setup.PaymentForm,{attrs:{"sendedForm":_setup.sendedForm},on:{"onClose":function($event){return _vm.$emit('onClose')}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }