import { ref } from 'vue';

const userSettingsValidation = ref(null)
const userChangePasswordValidation = ref(null)
const companySettingsValidation = ref(null)
const topUpBalanceValidation = ref(null)
const paymentForm = ref(null)
const inviteUserValidation = ref(null)

export default {
	userSettingsValidation,
	userChangePasswordValidation,
	companySettingsValidation,
	topUpBalanceValidation,
	paymentForm,
	inviteUserValidation,
}
